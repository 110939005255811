import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import "./Instagram.scss"
import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";
import bannerLottery from "../../assets/images/selectBanner.png";

import qrCode from "../../assets/images/qrcode.png";

import downloadApp from "../../assets/images/download.png";
import downloadBazzar from "../../assets/images/bazzar.png";
import instagramPic from "../../assets/images/instagram-pic.png";
import instagramLogo from "../../assets/images/instagram-logo.png";
import instagramKit from "../../assets/images/instagramKit-service.png";
import downloadImg1 from "../../assets/images/PWA.png";

function Instagram() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  return (
    <div>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-notFound qr-block">
        <p className="instagram-content" style={{ textAlign: "center" }}>
          <div className="borderContainer">
            <span>ابتدا وارد اپلیکیشن رابط اتحاد شده</span>
            <hr />
            <div className="app-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.rabetettehad&gl=GB"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={downloadApp} alt="downloadApp" />
              </a>
              <a
                href="https://cafebazaar.ir/app/com.rabetettehad"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={downloadBazzar} alt="downloadBazzar" />
              </a>
              <Link to="/pwa" className="pwa">
              <img src={downloadImg1} className="" alt="" />
            </Link>
            </div>
            <div className="grop">
              <div className="right-s">
                <h4>نحوه فعال سازی گارانتی محصولات :</h4>
                <span id="insta">
                  روش های قدیمی ثبت کد گارانتی و شرکت در قرعه کشی دیگر منسوخ گردیده و کارایی زیادی ندارد .
                  بدین منظور شرکت بهساز رابط اتحاد با ابتکاری نو و منحصر به فرد سامانه یکتا و هوشمندی توسط مهندسین زبده و مجرب طراحی کرده است که باعث افزایش کارایی ،سرعت و راحتی مشتریان عزیز و همچنین سهولت در پیگیری گارانتی گردیده است .
                  با این سامانه شما مشتریان عزیز بایستی QRcode دانلود اپلیکیشن موجود بر روی محصول را اسکن نموده و اپلیکیشن شرکت را از بستر Play Store  و بازار برای موبایل های سیستم عامل اندروید و همچنین از بستر سیب آپ، سیبچه و ... برای موبایل های سیستم عامل آیفون دانلود فرمایید و با وارد شدن به بخش خرید کن و جایزه ببر  QRcode مخصوص کالا های خریداری شده را اسکن فرمایید ، و با فشردن دکمه ثبت گارانتی خودرا فعال نمایید
                </span>
              </div>
              <div className="left-s">
                <img src={bannerLottery} className="bannerimage" alt="" />
              </div>
            </div>
          </div>
        </p>
        <div className="index-notFound">
          <img src={instagramPic} alt="instagramPic" />
          <p className="instagram-content">
            مسابقه اینستاگرامی یکی از بهترین روش های افزایش فالوور واقعی ، برند
            سازی و افزایش نرخ تعامل پیج اینستاگرام می باشد مسابقه اینستاگرامی و
            نحوه قرعه کشی کامنت های اینستاگرام دنبال کنندگان جهت اعتماد سازی و
            بیشتر دیده شدن کسب و کار شما در شبکه های اجتماعی بسیار ضروری است به
            این دلیل که اگر طراحی کمپین تبلیغاتی و برگزاری مسابقه به درستی انجام
            نشود سبب بدبینی کاربران نسبت به برند و کسب و کار شما خواهد شد
          </p>
          <p className="instagram-content">
            مسابقه اینستاگرامی یکی از بهترین روش های افزایش فالوور واقعی ، برند
            سازی و افزایش نرخ تعامل پیج اینستاگرام می باشد مسابقه اینستاگرامی و
            نحوه قرعه کشی کامنت های اینستاگرام دنبال کنندگان جهت اعتماد سازی و
            بیشتر دیده شدن کسب و کار شما در شبکه های اجتماعی بسیار ضروری است به
            این دلیل که اگر طراحی کمپین تبلیغاتی و برگزاری مسابقه به درستی انجام
            نشود سبب بدبینی کاربران نسبت به برند و کسب و کار شما خواهد شد
          </p>
          <img src={instagramLogo} alt="instagramLogo" />
          <p className="instagram-content">
            مسابقه اینستاگرامی یکی از بهترین روش های افزایش فالوور واقعی ، برند
            سازی و افزایش نرخ تعامل پیج اینستاگرام می باشد مسابقه اینستاگرامی و
            نحوه قرعه کشی کامنت های اینستاگرام دنبال کنندگان جهت اعتماد سازی و
            بیشتر دیده شدن کسب و کار شما در شبکه های اجتماعی بسیار ضروری است به
            این دلیل که اگر طراحی کمپین تبلیغاتی و برگزاری مسابقه به درستی انجام
            نشود سبب بدبینی کاربران نسبت به برند و کسب و کار شما خواهد شد
          </p>
          <Link className="instagramKitLink" to="/">
            ورود به پیج اینستاگرام
          </Link>
          <img src={instagramKit} alt="instagramKit" className="instagramKit" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Instagram;
