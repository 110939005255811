import React, { useState, useEffect } from "react";
import "./pwa.scss";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";
import image4 from "../../assets/images/image (4).jpg"
import image2 from "../../assets/images/image (2).jpg"
import image1 from "../../assets/images/image (1).jpg"
import bannerLottery from "../../assets/images/selectBanner.png";
import banner from "../../assets/images/bannnner.jpg";
import qrCode from "../../assets/images/qrcode.png";
import downloadApp from "../../assets/images/sibApp.png";
import downloadBazzar from "../../assets/images/bazzar.png";

function LearnQr() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <div>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-notFound qr-block">
            <div className="container">
                <dir className="titelcontainer">
                <h2 className="titel">برای نصب وب اپلیکیشن باربر در تلفن های همراه آیفون به ترتیب زیر عمل فرمایید</h2>
                </dir>
                <div className="discontain">
                <span>ابتدا مرور گر سافاری را باز کنید</span>
                <span>در مرورگر سافاری آدرس محل وب اپلیکیشن را تایپ بفرمایید</span>
                <span>https://www.shop.rabetettehadco.ir</span>
                </div>
                <div className="imagecontainer">
                    <img src={image4} alt="" />
                </div>
                <div className="discontain">
                <span>بر روی علامت فلش رو به بالا کلیک فرمایید و سپس با اسکرول کردن گزینه add to home screen را انتخاب نمایید</span>
                <span>شکل زیر نشان داده خواهد شد</span>
                <span>مطابق شکل زیر در بالای صفحه گزینه Add را انتخاب فرمایید</span>
                </div>
                <div className="imagecontainer">
                    <img src={image2} alt="" />
                </div>
                <div className="discontain">
                <span>مثل شکل زیر آیکون وب اپلیکیشن باربر یا PWA در صفحه موبایل شما جاگذاری خواهد شد</span>
                <span>هم اکنون می توانید از همه امکانات این PWA استفاده فرمایید</span>
                </div>
                <div className="imagecontainer">
                    <img src={image1} alt="" />
                </div>
            </div>
      </div>
      <Footer />
    </div>
  );
}

export default LearnQr;
