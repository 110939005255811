import React, { useState, useEffect } from "react";
import "./MobileMenu.scss";

import { Link, useNavigate } from "react-router-dom";

import $ from "jquery";

import logo from "../../assets/svg/logo.svg";
import down from "../../assets/svg/sort-down.svg";

import { fetchApi } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { FaInstagramSquare, FaTelegramPlane } from "react-icons/fa";
import { SiAparat } from "react-icons/si";
import { AiFillTwitterSquare } from "react-icons/ai";
import eng from "../../assets/images/eng.png";

function MobileMenu({ token }) {

  const navigate = useNavigate()

  const [cat, setCat] = useState("");

  useEffect(() => {
    fetchApi("api/admin/shop/fetch_cat", "", "").then((res) => {
      setCat(res.data);
    });
  }, [token]);

  const productHandler = (e) => {
    e.preventDefault();
    $(".secound-list").slideToggle();
    $(".product-title").toggleClass("titleColor");
    $(".down-product").toggleClass("rotate");
  };

  const aboutHandler = (e) => {
    e.preventDefault();
    $(".third-list").slideToggle();
    $(".about-title").toggleClass("titleColor");
    $(".down-about").toggleClass("rotate");
  };

  return (
    <>
      <Toaster position="top-center" />
      <div className="menuMobile">
        <img src={logo} alt="logo" />
        <div className="left">
          <a href="https://www.shop.en.rabetettehadco.ir/">
            <button className="language-change"><span className="English"> EN </span><img src={eng} alt="english" width="28px" className="english-icon" /> </button>
          </a>
        </div>
        <ul className="menu-list">
          <li className="menu-item">
            <Link to="/" className="menu-link">
              خانه
            </Link>
          </li>
          <hr />
          <li className="menu-item">
            <a
              href="/#"
              className="menu-link product-title"
              onClick={productHandler}
            >
              محصولات
              <img src={down} alt="down" className="down-product" />
            </a>

            <ul className="secound-list">
              {cat
                ? cat.map((category, index) => {
                  return (
                    <li className="third-item" key={index}>
                      <Link to={`/filter/${category._id}`}>
                        {category.title}
                      </Link>
                    </li>
                  );
                })
                : null}
            </ul>
          </li>
          <hr />
          <li className="menu-item">
            <a
              href="/#"
              className="menu-link about-title"
              onClick={aboutHandler}
            >
              رابط اتحاد
              <img src={down} alt="down" className="down-about" />
            </a>
            <ul className="third-list">
              <li className="third-item">
                <Link to="/agent">نمایندگان</Link>
              </li>
              <li className="third-item">
                <Link to="/aboutUs">درباره ما</Link>
              </li>
              <li className="third-item">
                <Link to="/contactUs">تماس با ما</Link>
              </li>
            </ul>
          </li>
          <hr />
          <li className="menu-item">
            <a href="/#" className="menu-link">
              آموزش و اعلانات
              <img src="" alt="" />
            </a>
          </li>
          {
            window.innerWidth < 500 ?
              <li
                onClick={() => {
                  if (!token) {
                    return toast.error("ابتدا وارد حساب کاربری خود شوید", { duration: 2000 })
                  }
                  navigate("/garantySubmit")
                }}
              >
                <span>ثبت گارانتی</span>
              </li>
              : null
          }
        </ul>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
