import React, { useState } from "react";
import "./Pay.scss";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import faildImg from "../../assets/images/loader_failed_01.gif";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function FaildPay() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>

      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}

      <Header handler={signInHandler} menuHanlder={mobileMenuHandler} basketHandler={getProductCountBasket()} />
      <main className="index-faildPay">
        <img src={faildImg} alt="faildImg" />
        <span className="title-content">نه!</span>
        <span className="subbtitle-content">پرداخت ناموفق بود</span>
        <span className="details-content">
          پس از بررسی ، در صورت نیاز به شما ایمیل خواهیم داد
        </span>
        <span className="site-contect">www.RabetEttehadco.ir</span>
        <a href="https://www.rabetettehadco.ir">بازگشت به صفحه اصلی</a>
      </main>
      <Footer />
    </>
  );
}

export default FaildPay;
