import React, { useState, useEffect } from "react";
import "./Filter.scss";

import { useParams } from "react-router-dom";

import $ from "jquery";

import ReactPaginate from "react-paginate";

import filterIcon from "../../assets/svg/filter-svgrepo-com.svg";

import { BsFolder2Open } from "react-icons/bs";

import { fetchApi, getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ProductCard from "../../components/ProductCard/ProductCard";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { ImSearch } from "react-icons/im";

library.add(faCircleNotch);

function Filter() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [catId, setCatId] = useState("");

  const [products, setProducts] = useState([]);

  const { id } = useParams();

  const [cat, setCat] = useState("");
  const [sort, setSort] = useState("normal");
  const [category, setCategory] = useState(id);
  const [priceGt, setPriceGt] = useState("");
  const [priceLt, setPriceLt] = useState("");
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [search, setSearch] = useState("");

  let special = "all";

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCatId(id);

    if(id !== catId) {
      setCategory(id);
    }

    $(".prooducts-list").css({ filter: "blur(4px)" });

    fetchApi(`/api/user/shop/fetch_product`, "", {
      sort,
      category,
      price_gt: priceGt,
      price_lt: priceLt,
      special,
      page,
    }).then((res) => {
      setProducts(res.product);
      setMaxPage(res.max_page);
      console.log(res);

      if (res.status_code === 200) {
        setLoading(false);
        $(".prooducts-list").css({ filter: "blur(0px)" });
      }
    });

    fetchApi("api/admin/shop/fetch_cat", "", "").then((res) => {
      setCat(res.data);
    });
  }, [sort, category, priceGt, priceLt, special, page, id, catId]);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".fiter-catalog").css({ display: "none" });
    $(".fiter-price").css({ display: "none" });
    $(".fiter-cat").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const filterHandler = () => {
    $(".cover").css({ display: "block" });
    $(".fiter-catalog").css({ display: "block" });
  };

  const priceFilterHandler = () => {
    $(".cover").css({ display: "block" });
    $(".fiter-price").css({ display: "block" });
  };

  const filterCategory = () => {
    $(".cover").css({ display: "block" });
    $(".fiter-cat").css({ display: "block" });
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    window.scroll({
      top: 20,
      behavior: "smooth",
    });
  };

  const categoryHandler = (e) => {
    e.preventDefault();
    if (e.target.id.length > 0) {
      setLoading(true);
      $(".menu-item").removeClass("menu-item-active");
      $(e.target).addClass("menu-item-active");
      setCategory(e.target.id);
      $(".prooducts-list").css({ filter: "blur(4px)" });
      $(".cover").css({ display: "none" });
      $(".fiter-cat").css({ display: "none" });
    }
  };

  const priceLtHandler = (e) => {
    if (e.target.value.length > 0) {
      setLoading(true);
      setPriceLt(parseInt(e.target.value));
      $(".prooducts-list").css({ filter: "blur(4px)" });
      $(".fiter-price").css({ display: "none" });
      $(".cover").css({ display: "none" });
    }
  };

  const priceGtHandler = (e) => {
    if (e.target.value.length > 0) {
      setLoading(true);
      setPriceGt(parseInt(e.target.value));
      $(".prooducts-list").css({ filter: "blur(4px)" });
      $(".fiter-price").css({ display: "none" });
      $(".cover").css({ display: "none" });
    }
  };

  const sortHandler = (e) => {
    e.preventDefault();
    if (e.target.id.length > 0) {
      setLoading(true);
      setSort(e.target.id);
      $(".item").removeClass("menu-item-active-sort");
      $(e.target).addClass("menu-item-active-sort");
      $(".prooducts-list").css({ filter: "blur(4px)" });
      $(".cover").css({ display: "none" });
      $(".fiter-catalog").css({ display: "none" });
    }
  };

  const searchHandler = (e) => {
    console.log(e);
    setSearch(e);

    if (e.length > 6) {
      fetchApi(`/api/user/shop/fetch_product`, "", {
        sort,
        category,
        price_gt: priceGt,
        price_lt: priceLt,
        special,
        page,
        search,
      }).then((res) => {
        setProducts(res.product);
        setMaxPage(res.max_page);

        if (res.status_code === 200) {
          setLoading(false);
          $(".prooducts-list").css({ filter: "blur(0px)" });
        }
      });
    }
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}

      <div className="fiter-catalog">
        <div className="title">مرتب سازی بر اساس</div>
        <div className="items" onClick={sortHandler}>
          <a href="/#" className="item" id="cheapest">
            ارزان ترین
          </a>
          <hr />
          <a href="/#" className="item" id="expensive">
            گران ترین
          </a>
          <hr />
          <a href="/#" className="item" id="Bestsellers">
            پرفروش ترین
          </a>
          <hr />
          <a href="/#" className="item" id="normal">
            جدید ترین
          </a>
          <hr />
          <a href="/#" className="item" id="Popular">
            محبوب ترین
          </a>
          <hr />
        </div>
      </div>

      <div className="fiter-price">
        <div className="title">قیمت</div>
        <div className="items">
          <label htmlFor="">حداقل</label>
          <select name="" id="sort" onChange={priceLtHandler}>
            <option value="">انتخاب کنید</option>
            <option value="10000">10 هزار تومان</option>
            <option value="40000">40 هزار تومان</option>
            <option value="60000">60 هزار تومان</option>
            <option value="80000">80 هزار تومان</option>
            <option value="100000">100 هزار تومان</option>
          </select>
          <label htmlFor="">حداکثر</label>
          <select name="" id="" onChange={priceGtHandler}>
            <option value="">انتخاب کنید</option>
            <option value="200000">200 هزار تومان</option>
            <option value="400000">400 هزار تومان</option>
            <option value="600000">600 هزار تومان</option>
            <option value="800000">800 هزار تومان</option>
            <option value="10000000">یک میلیون تومان</option>
          </select>
        </div>
      </div>

      <div className="fiter-cat">
        <div className="title">دسته بندی محصولات</div>
        <div className="items" onClick={categoryHandler}>
          {cat ? (
            cat.map((category, index) => {
              return (
                <div key={index}>
                  <span className="menu-item" id={category._id}>
                    <a href="/#" className="item" id={category._id}>
                      {category.title}
                    </a>
                  </span>
                  <hr />
                </div>
              );
            })
          ) : (
            <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
          )}
        </div>
      </div>
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
        searchHandlerContent={searchHandler}
      />
      <main className="index-filter">
        <div className="search-mobile">
          <input
            type="text"
            placeholder="جستجو محصولات"
            onChange={(e) => searchHandler(e.target.value)}
          />
          <ImSearch className="search-icon" />
        </div>
        <div className="mobile-filters">
          <section className="catalog">
            <button onClick={filterCategory}>دسته بندی محصولات</button>
          </section>
          <section className="filter">
            <button onClick={filterHandler}>مرتب سازی</button>
          </section>
          <section className="price">
            <button onClick={priceFilterHandler}>قیمت</button>
          </section>
        </div>
        <aside className="aside">
          <div className="menu" onClick={categoryHandler}>
            <section className="menu-title">دسته بندی محصولات</section>
            <hr />
            {cat ? (
              cat.map((category, index) => {
                return (
                  <span className="menu-item" key={index} id={category._id}>
                    <BsFolder2Open className="folder" /> {category.title}
                  </span>
                );
              })
            ) : (
              <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
            )}
          </div>

          <div className="menu">
            <section className="menu-title">قیمت</section>
            <hr />
            <span className="menu-item">
              <div className="price-title first">حداقل </div>
              <select name="" id="sort" onChange={priceLtHandler}>
                <option value="">انتخاب کنید</option>
                <option value="10000">10 هزار تومان</option>
                <option value="40000">40 هزار تومان</option>
                <option value="60000">60 هزار تومان</option>
                <option value="80000">80 هزار تومان</option>
                <option value="100000">100 هزار تومان</option>
              </select>
            </span>
            <span className="menu-item">
              <div className="price-title">حداکثر </div>
              <select name="" id="" onChange={priceGtHandler}>
                <option value="">انتخاب کنید</option>
                <option value="200000">200 هزار تومان</option>
                <option value="400000">400 هزار تومان</option>
                <option value="600000">600 هزار تومان</option>
                <option value="800000">800 هزار تومان</option>
                <option value="10000000">یک میلیون تومان</option>
              </select>
            </span>
          </div>
        </aside>
        <article className="products">
          <div className="filter-section">
            <div className="title">کالای دیجیتال</div>
            <div className="filter" onClick={sortHandler}>
              <span className="item first">مرتب سازی بر اساس</span>
              <span className="item" id="cheapest">
                ارزان ترین
              </span>
              <span className="item" id="expensive">
                گران ترین
              </span>
              <span className="item" id="Bestsellers">
                پر فروش
              </span>
              <span className="item" id="normal">
                جدید ترین
              </span>
              <span className="item" id="Popular">
                محبوب ترین
              </span>
            </div>
            <div className="filter-icon">
              <img src={filterIcon} alt="filter" />
            </div>
          </div>
          {loading ? (
            <div className="product-cover">
              <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
            </div>
          ) : null}
          <div className="prooducts-list">
            {products.length > 0 ? (
              products.map((product, index) => {
                return (
                  <ProductCard
                    key={index}
                    id={product._id}
                    name={product.title}
                    pic={product.pics[0]}
                    point={product.scoore}
                    percent={parseInt(product.per)}
                    price={product.price}
                    offPrice={product.priceoff}
                    agentPrice={product.priceagent}
                    agentOffPrice={product.priceagentoff}
                  />
                );
              })
            ) : products !== undefined && products.length > 0 ? (
              <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
            ) : (
              <>
                <p className="product-card title">محصولی وجود ندارد</p>
              </>
            )}
          </div>
          <ReactPaginate
            previousLabel={"قبلی"}
            nextLabel={"بعدی"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={maxPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </article>
      </main>
      <Footer />
    </>
  );
}

export default Filter;
