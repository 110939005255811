import React from "react";
import "./Button.scss";

function Button({ title, onClickAction, outline, type = "button" }) {
  return (
    <button
      type={type}
      onClick={(e) => onClickAction(e)}
      className={`button ${outline ? "button-outline" : ""}`}
    >
      {title}
    </button>
  );
}

export default Button;
