import "./shop.scss";
import Pix from "../../assets/images/12.png";
const Shop = () => {
  return (
    <div className="index-notFound qr-block">
      <p className="instagram-content" style={{ textAlign: "center" }} />
      <div className="borderContainer">
        <span>ابتدا وارد اپلیکیشن رابط اتحاد شده</span>
        <hr />

        <div className="grop">
          <div className="right-s">
            <h4>نحوه فعال سازی گارانتی محصولات :</h4>
            <span style={{fontSize : "19px"}}>
              نمایندگان محترم شما میتوانید با استفاده از qr-code موجود بر روی تراکت اپلیکیشن ویژه نمایندگان را دانلود کرده و از امکانات زیر بهره مند شوید .
              <br/>
              1 – بررسی گارانتی محصولات <br/>
              2 – ثبت نام نمایندگان فروش <br/>
              3 – بخش فروشگاه رابط اتحاد با قابلیت خرید به صورت نماینده <br/>
              4 – ارتباط با پشتیبانی و تماس با ما <br/>
              5 – قوانین و مقررات <br/>
              6 – درباره ما <br/>

            </span>
          </div>

          <div className="left-s">
            <div className="mobilediv">
              <img src={Pix} className="bannerimage" alt="" />
            </div>
            <div className="downloaddiv">
              <a href="https://www.shop.rabetettehadco.ir/download/rabetettehad_agent.apk">
                <img id="DownlnloadImg" src={require("../../assets/images/sibApp.png")} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p />
      <div className="index-notFound"></div>
    </div>
  );
};

export default Shop;
