import React, { useState, useEffect } from "react";
import "./Basket.scss";

import $ from "jquery";

import upIcon from "../../assets/svg/plus.svg";
import downIcon from "../../assets/svg/less.svg";

import { ToastContainer, toast } from "react-toastify";

import { sliceNumber, getProductCountBasket, fetchApi } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

// import { CgCloseR } from "react-icons/cg";
import { AiOutlineClockCircle } from "react-icons/ai";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function Basket({ token }) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [offCode, setOffCode] = useState("");
  const [offCodeSet, setOffCodeSet] = useState(false);
  const [offCodePercent, setOffCodePercent] = useState("");
  const [basketDetails, setBasketDetails] = useState("");
  const [basketCount, setBasketCount] = useState(0);
  const [profileValidate, setProfileValidate] = useState(false);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  //get basket
  let basket = localStorage.getItem("basket");

  //if not exist basket or exist in localstorage
  if (basket === null) {
    basket = [];
  } else {
    basket = JSON.parse(basket);
  }

  useEffect(() => {
    fetchApi(`/api/user/shop/calc_cart`, token, { productList: basket }).then(
      (res) => {
        setBasketDetails(res);

        if (res.error) {
          res.error_msg.map((err) => {
            toast.error(err, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
        }
      }
    );

    fetchApi(`/api/user/validate`, token, "").then((res) => {
      if (res.status_code === 200) {
        setProfileValidate(true);
      } else if (res.description === "Access denied!") {
        setProfileValidate(false);
      }
    });
  }, [basketCount, token]);

  const submitOffCode = () => {
    fetchApi(`/api/user/shop/discode_test`, token, { code: offCode }).then(
      (res) => {
        if (res.description === "code not is exist!") {
          toast.error("کد تخفیف موجود نیست", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.removeItem("offCode", offCode);
        } else if (res.status_code === 200) {
          setOffCodeSet(true);
          setOffCodePercent(res.per);
          toast.success("کد تخفیف اعمال شد", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.setItem("offCode", offCode);
        } else if (res.status_code === 402) {
          toast.success(res.description_fa, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.removeItem("offCode", offCode);
        }
      }
    );
  };

  //add basket button handler
  const upperCountHandler = (e) => {
    //get product id
    let id = e.target.id;

    //check index product varable
    let indexProduct = false;

    //get index product in localstorage
    basket.forEach((item, index) => {
      if (indexProduct === false) {
        if (item.id === id) {
          indexProduct = index;
        } else {
          indexProduct = false;
        }
      }
    });

    //upper count product or add product to list
    if (indexProduct !== false && indexProduct !== undefined) {
      let count = basket[indexProduct].count;
      basket[indexProduct].count = count + 1;
    }

    //save in localstorage
    localStorage.setItem("basket", JSON.stringify(basket));
    setBasketCount(getProductCountBasket());
  };

  const downCountHanlder = (e) => {
    //get product id
    let id = e.target.id;

    //check index product varable
    let indexProduct = false;

    //get index product in localstorage
    basket.forEach((item, index) => {
      if (indexProduct === false) {
        if (item.id === id) {
          indexProduct = index;
        } else {
          indexProduct = false;
        }
      }
    });

    //upper count product or add product to list
    if (indexProduct !== false && indexProduct !== undefined) {
      let count = basket[indexProduct].count;
      if (count >= 1) {
        basket[indexProduct].count = count - 1;
      } else {
        basket.splice(indexProduct, 1);
      }
    }

    //save in localstorage
    localStorage.setItem("basket", JSON.stringify(basket));
    setBasketCount(getProductCountBasket());
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-basket">
        <div className="basket-card">
          <table>
            <tr>
              <th>محصول</th>
              <th>قیمت</th>
              <th>تعداد</th>
              <th>قیمت کل</th>
            </tr>
            {basketDetails
              ? basketDetails.products.map((product, index) => {
                  return (
                    <>
                      <hr />
                      <tr key={index}>
                        <td className="product-title">
                          {/* <CgCloseR /> */}
                          <img src={product.pics[0]} alt="productPic" />
                          <div>
                            <h2>{product.name}</h2>
                            <span>
                              <AiOutlineClockCircle /> آماده ارسال
                            </span>
                          </div>
                        </td>
                        <td className="price">
                          <span>
                            {product
                              ? sliceNumber(
                                  product.priceoff <= 0
                                    ? product.price
                                    : product.priceoff
                                )
                              : null}{" "}
                            تومان
                          </span>
                        </td>
                        <td className="counter">
                          <span>
                            <img
                              src={upIcon}
                              alt="upIcon"
                              id={product.product_id}
                              onClick={upperCountHandler}
                            />
                          </span>
                          <span className="count">{product.count}</span>
                          <span>
                            <img
                              src={downIcon}
                              alt="downIcon"
                              id={product.product_id}
                              onClick={downCountHanlder}
                            />
                          </span>
                        </td>
                        <td className="sumPrice">
                          <span>
                            {product
                              ? sliceNumber(
                                  product.priceoff <= 0
                                    ? product.price
                                    : product.priceoff
                                )
                              : null}{" "}
                            تومان
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                })
              : null}
            <hr />
          </table>
          <div className="row">
            <div className="copoun">
              <input
                onChange={(e) => {
                  setOffCode(e.target.value);
                }}
                type="text"
                placeholder="کد تخفیف"
              />
              <button onClick={submitOffCode}>اعمال تخفیف</button>
            </div>
            <div className="sumPrice">
              {basketDetails ? sliceNumber(basketDetails.product_mony) : null}{" "}
              تومان
            </div>
          </div>
          <div className="row">
            <div className="tranport">
              هزینه حمل و نقل :{" "}
              <span>
                {basketDetails
                  ? sliceNumber(basketDetails.delivery_mony)
                  : null}
              </span>{" "}
              تومان
            </div>
            <div className="sumPrice">
              جمع کل :{" "}
              <span>
                {offCodeSet
                  ? basketDetails
                    ? sliceNumber(
                        basketDetails.total_mony -
                          basketDetails.total_mony * offCodePercent
                      )
                    : null
                  : basketDetails
                  ? sliceNumber(basketDetails.total_mony)
                  : null}
              </span>{" "}
              تومان
            </div>
          </div>
          <div className="row">
            <div></div>
            <div className="buttons">
              <button className="otherProduct">خرید محصولات دیگر</button>
              <button
                className="countinue"
                onClick={() => {
                  if (profileValidate === false) {
                    window.location.href = "/basketForm";
                  } else {
                    window.location.href = "/basketFormV";
                  }
                }}
              >
                ادامه سفارش
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
