import React, { useState, useEffect } from "react";
import "./Rules.scss";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function Privacy() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-rule">
        <div className="title">حریم خصوصی</div>
        <p>
          رابط اتحاد با احترام به حریم شخصی کاربران، تنها برای خرید محصول،
          اطلاعاتی را از کاربر دریافت می کند. برای ارسال محصولات از کاربران
          اطلاعاتی مانند آدرس، شماره تلفن و ایمیل دریافت می شود تا پردازش و
          ارسال کالا در سریع ترین زمان ممکن انجام شود و درصورتی که هرگونه مشکلی
          بوجود آمد رابط اتحاد بتواند هویت اصلی خریدار را مشخص کند و پاسخگوی
          کاربران باشد.
          <br />
          <br />
          بنابراین ثبت آدرس، ایمیل و … توسط مشتری به منزله تایید و صحت آن ها است
          و در صورتی که این اطلاعات ناقص ثبت شوند، رابط اتحاد برای دریافت
          اطلاعات تکمیلی با کاربران تماس می گیرد.
          <br />
          <br />
          همچنین کاربران می توانند برای دریافت کالا از آدرس و شماره تلفن شخص
          دیگری استفاده نمایند تا رابط اتحاد سفارش را به آدرس دیگری ارسال نماید.
          <br />
          <br />
          کلیه فعالیت های وب سایت رابط اتحاد مبتنی بر قوانین تجارت الکترونیک
          انجام می شود و تمامی اطلاعات کاربران نزد رابط اتحاد محفوظ می باشد.
          <br />
          <br />
          رابط اتحاد به هیچ عنوان اطلاعات کاربران را در اختیار شرکت های تبلیغاتی
          قرار نداده و فقط ممکن است رابط اتحاد به منظور بهینه سازی وب سایت و
          تحقیقات بازاریابی با مشتریان خود تماس بگیرد.
          <br />
          <br />
          رابط اتحاد ممکن است به منظور رعایت قوانین وب سایت نظرهای ارسالی
          کاربران را ویرایش کند. کاربران ضمن استفاده از خدمات وب سایت مکس خودرو،
          حق ویرایش اطلاعات و استفاده از آنها را در چارچوب فوق الذکر به رابط
          اتحاد اعطا نموده و حق اعتراض را از خود سلب می نماید.
          <br />
          <br />
          حفظ و نگهداری رمز عبور و نام کاربری بر عهده کاربران بوده و مسئولیت
          هرگونه خسارت احتمالی ناشی از عدم رعایت موارد ذکر شده، با خود کاربر
          بوده و کاربر حق هرگونه ادعا علیه رابط اتحاد را از خود سلب می کند.
          <br />
          <br />
          رابط اتحاد همانند سایر وب سایت ها تنها از جمع آوری IP و کوکی استفاده
          می کند. اطلاعات شخصی کاربران نزد سایت رابط اتحاد محفوظ می باشد و از
          دادن اطلاعات کاربران به شخص یا سازمان دیگر خودداری می کند مگر اینکه با
          حکم مقام قضایی یا اداری صالحه یا طبق قوانین و مقررات رایج کشور موظف می
          باشد در اختیار مراجع ذی صلاح قرار دهد. در این موارد هیچ گونه مسئولیت
          قانونی مبنی بر جبران خسارت برای رابط اتحاد وجود ندارد و کاربران با
          اعلام رضایت خود در استفاده از خدمات وب سایت ضمناً حق هرگونه اعتراض را
          از خود سلب نموده اند.
          <br />
          <br />
        </p>
        <hr />
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
