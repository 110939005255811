import React, { useState, useEffect } from "react";
import "./Header.scss";

import { Link, useLocation, useNavigate } from "react-router-dom";

import $ from "jquery";

import Logo from "../../assets/svg/logo.svg";

import { ImSearch, ImFacebook2 } from "react-icons/im";
import { BsBasket2Fill } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import { TiArrowSortedDown } from "react-icons/ti";
import { FaInstagramSquare, FaTelegramPlane } from "react-icons/fa";
import { IoMdArrowDropleft } from "react-icons/io"
import { SiAparat } from "react-icons/si";
import { AiFillTwitterSquare } from "react-icons/ai";

import { fetchApi } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import eng from "../../assets/images/eng.png";

library.add(faCircleNotch);

function Header({
  handler,
  menuHanlder,
  token,
  searchHandlerContent,
  basketHandler,
}) {
  const [cat, setCat] = useState("");

  const history = useLocation();
  const navigate = useNavigate();

  const searchHandler = () => {
    if (history.pathname !== "/filter") {
      navigate("/filter/all");
      $("#searchInput").focus();
    }
  };

  useEffect(() => {
    fetchApi(`/api/user/validate`, token, "").then((res) => {
      if (res.status_code === 200) {
        $("#signIn").css({ display: "none" });
        $("#profile").css({ display: "flex" });
      } else if (res.description === "Access denied!") {
        $(".buttonText").css({ display: "flex" });
        $(".buttonSpin").css({ display: "none" });
      }
    });

    fetchApi("api/admin/shop/fetch_cat", "", "").then((res) => {
      setCat(res.data);
    });
  }, [token]);

  return (
    <>
      <header>
        <div className="top">
          <div className="right">
            <div className="hamburger-menu">
              <HiMenu className="menu-icon" onClick={menuHanlder} />
            </div>
            <Link to="/">
              <img src={Logo} alt="" className="logo" />
            </Link>
            <div className="input">
              <input
                className="search"
                type="search"
                id="searchInput"
                placeholder="جستجو محصولات"
                onClick={searchHandler}
                onChange={(e) => searchHandlerContent(e.target.value)}
              />
              <ImSearch className="search-icon" />
            </div>
          </div>
          <div className="left">
            <Link to="/basket" className="go-to-bascket">
              <BsBasket2Fill className="bascket-icon" />
              <span className="badge">{basketHandler}</span>
            </Link>
            <button id="signIn" className="sign-in" onClick={handler}>
              <span className="buttonText">ورود و ثبت نام</span>
              <span className="buttonSpin">
                <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
              </span>
            </button>
            <button
              className="sign-in"
              id="profile"
              onClick={(e) => (window.location.href = "/profile")}
            >
              پروفایل
            </button>
          </div>
        </div>
      </header>
      <div className="bottom">
        <div className="header-bottom">
          <div className="right">
            <HiMenu className="menu-icon" />
            <ul>
              <li>
                <Link to="/">خانه</Link>
              </li>
              <li className="ha-drop-down">
                <TiArrowSortedDown className="arrow-down" /> محصولات
                <div className="drop-down">
                  <ul>
                    {cat
                      ? cat.map((category, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/filter/${category._id}`}>
                              <span><IoMdArrowDropleft className="arrow-left" />{category.title}</span>
                            </Link>
                          </li>
                        );
                      })
                      : null}
                  </ul>
                </div>
              </li>
              <li className="ha-drop-down">
                <TiArrowSortedDown className="arrow-down" /> رابط اتحاد
                <div className="drop-down">
                  <ul>
                    <li>
                      <Link to="/agent">نمایندگان</Link>
                    </li>
                    <li>
                      <Link to="/aboutUs">درباره ما</Link>
                    </li>
                    <li>
                      <Link to="/contactUs">تماس با ما</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="https://blog.rabetettehadco.ir">اموزش و اعلانات</a>
              </li>
            </ul>
          </div>
          <div className="left">
            <a href="https://www.shop.en.rabetettehadco.ir/">
              <button className="language-change"><span className="English"> EN </span><img src={eng} alt="english" width="28px" className="english-icon" /> </button>
            </a>
            <a href="https://www.telegram.org/rabetettehadco/" className="social-link" target={"_blank"}>
              <FaTelegramPlane className="social-icon telegram-icon" />
            </a>
            <a href="https://www.aparat.com/rabetettehadco/" className="social-link" target={"_blank"}>
              <SiAparat className="social-icon " />
            </a>
            <a href="https://www.twitter.com/rabetettehadco/" className="social-link" target={"_blank"}>
              <AiFillTwitterSquare className="social-icon twitter-icon" />
            </a>
            <a href="https://www.instagram.com/rabetettehadco/" className="social-link" target={"_blank"}>
              <FaInstagramSquare className="social-icon instagram-icon" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
