import React, { useState, useEffect } from "react";
import "./Rules.scss";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function Rules() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-rule">
        <div className="title">شرایط و قوانین</div>
        <p>
          استفاده از فروشگاه اینترنتی رابط اتحاد به معنی توافق کامل شما با شرایط
          و ضوابط ذیل تلقی می گردد: <br />
          ۱-۱- خرید کالا از فروشگاه اینترنتی رابط اتحاد بر مبنای قوانین و آئین
          نامه های موجود در تجارت الکترونیک و با رعایت کامل تمام قوانین جمهوری
          اسلامی ایران صورت می پذیرد.
          <br />
          ۱-۲-خدمات و محتویات این سایت براى استفاده شخصى و غیر تجارى مشتریان
          عرضه شده است و مالکیت معنوی اطلاعات موجود در آن متعلق به این فروشگاه
          بوده و هر گونه سوء استفاده از این اطلاعات پیگرد قانونی دارد. استفاده
          غیرتجاری از محتویات سایت با ذکر منبع بلامانع است و اگر مایلید از
          اطلاعات موجود در سایت جهت مقاصد تجاری استفاده نمایید باید از طریق پست
          الکترونیکی info@rabetettehadco.irدرخواست خود را ارسال نموده و اجازه نامه
          کتبی اخذ نمایید.
          <br />
          ۱-۳- اطلاعات درج شده در خصوص مشخصات فنی کالاهای ارائه شده توسط
          کارشناسان فنی و از شرکت های سازنده طرف قراداد اخذ گردیده است، ممکن است
          اطلاعات ارائه شده در فروشگاه اینترنتی رابط اتحاد در مواردی غیر دقیق یا
          دارای خطاهای نگارشی باشد، فروشگاه اینترنتی رابط اتحاد مسئولیتی در قبال
          اطلاعات ارائه شده برعهده نخواهد گرفت. این فروشگاه به طور مداوم نسبت به
          بررسی و بروز رسانی اطلاعات و محتویات آن اقدام خواهد نمود.
          <br />
          ۱-۴-کاربران هنگام سفارش کالا ملزم می باشند اطلاعات صحیح کامل را در
          پایگاه درج کنند. بدیهی است کاستی یا نادرستی اطلاعات مانع تکمیل درست و
          به موقع سفارش خواهد شد.
          <br />
          ۱-۵-فروشگاه اینترنتی رابط اتحاد هیچ گونه مسئولیتی را در مورد کارکرد
          سایت که می تواند ناشى از عوامل خارج از حوزه مدیریت این سایت باشند
          (همانند نقص اینترنت، مسائل مخابراتى، تجهیزات سخت افزاری و غیره) نمی
          پذیرد.
          <br />
          ۱-۶- تمام ابزاری که در فروشگاه اینترنتی رابط اتحاد با هدف ارتباط با
          مشتریان به کار گرفته می شود و فقط برای همین منظور طراحی شده است.
          کاربران متعهد هستند که از این ابزار صرفا برای ارسال و دریافت اطلاعاتی
          استفاده نمایند که مناسب و مرتبط با بحث سفارش محصولات باشد.
          <br />
          ۱-۷- سایر سایت ها و لینکهایی که برای ارائه اطلاعات بیشتر و کاملتر به
          فروشگاه اینترنتی رابط اتحاد لینک شده اند مستقل بوده، و فروشگاه
          اینترنتی رابط اتحاد مسئولیتی در قبال محتویات آنها ندارد.
          <br />
          ۱-۸-ارتباط فروشگاه اینترنتی رابط اتحاد با کاربران از طریق
          www.rabetettehadco.ir بر قرار می گردد و فقط با استفاده از اطلاعاتی که
          کاربران در سایت درج کرده اند (همانند نشانی، تلفن و …) صورت می پذیرد.
          <br />
          ۱-۹- فروشگاه اینترنتی رابط اتحاد به هیچ وجه اطلاعات منحصر بفرد کاربران
          را به اشخاص و طرفین غیر واگذار نخواهد کرد و ضمنا با استفاده از آخرین
          فن آوری ها متعهد است حتی المقدور از حریم شخصی کاربران دفاع کند.
          <br />
        </p>
        <hr />
        <div className="title">شرایط و ضوابط خدمات فروش کالا</div>
        <p>
          از نظر حقوقی، در معاملات فروشگاه اینترنتی رابط اتحاد، سایت
          www.rabetettehadco.ir به عنوان فروشنده و کاربر اینترنتی سفارش دهنده به
          عنوان خریدار تلقی می شوند.
          <br />
          ۲-۱-فروش کالا بر مبنای نوع محصولات و تعداد صورت می گیرد که خریدار آن
          را در فروشگاه اینترنتی رابط اتحاد درج نموده است.
          <br />
          ۲-۲-فروشنده قبل آنکه خریدار سفارش کالا را ثبت نماید، مشخصات و زمان
          ارسال کالا را تعیین کرده و به اطلاع خریدار می رساند. همچنین تمام مراحل
          تا زمان تحویل با خریدار بصورت پست الکترونیکی و یا پیام کوتاه هماهنگ
          خواهد شد.
          <br />
          ارزش کالا یا کالاهای سفارش داده شده طبق قیمت سایت محاسبه شده است که با
          کسر شدن تخفیف محصولات در صورت وجود محاسبه خواهد شد.
          <br />
          ۲-۳- مکان و زمان تحویل کالا توسط خریدار در فروشگاه اینترنتی رابط اتحاد
          درج شده و این فروشگاه به عنوان فروشنده متعهد است با رعایت سیاستهای
          معین ارسال کالا، سفارش را در آن مکان و زمان تحویل دهد. عدم حضور سفارش
          دهنده در مکان و زمان تعیین شده، فروشنده سیاست های خاص مربوطه را اعمال
          خواهد نمود.
          <br />
          ۲-۴- در صورتی که فروشنده به دلایلی که خارج از اراده او می باشد، توان
          تحویل کالا را در موعد مقرر نداشته باشد و طرفین درباره یک موعد جدید به
          توافق نرسند، هریک از طرفین حق دارند بدون هیچگونه هزینه ای سفارش را فسخ
          نموده و وجه واریزی را در مطابق با سیاست های خاص مربرط به عودت کالا،
          باز پس گیرد.
        </p>
        <hr />
        <div className="title">شرایط حمل و تحویل کالا</div>
        <p>
          ارسال سفارشات به سراسر ایران صورت می پذیرد.
          <br />
          مدت زمان تحویل محصولات در توضیحات محصول درج گردیده است. در ضمن توجه به
          آن زمان مربوطه یک روز کاری پس از واریز وجه و صدور صورتحساب می باشد.
          (روزهای تعطیل رسمی محاسبه نخواهد شد) مکان تحویل کالا نشانی است که شما
          مشتری گرامی در حین ثبت سفارش در وب سایت درج می نمائید. این نشانی ملاک
          ماموران حمل و لجستیک فروشگاه می باشد، پس لازم در صورت تغییر نشانی
          سریعا آن را به اطلاع کارشناسان ما برسانید.
          <br />
          ساعت تحویل کالای خریداری شده، بسته به زمان حضور شما در آرس درج شده می
          باشد که قبلا با شما هماهنگ خواهد شد و میتواند بنا به پیشنهاد شما تغییر
          نماید.
          <br />
          محصولات ارسالی برای خریداران محترم بنا به نوع محصولات بصورت بسته بندی
          شده بوده و نصب آن به عهده خریدار می باشد.
        </p>
        <hr />
        <div className="title">شرایط و ضوابط خدمات پس از فروش کالا</div>
        <p>
          الف) برگشت کالا:
          <br />
          الف – ۱) قبل از ارسال کالا: در صورت انصراف از دریافت کالا پس از تسویه
          حساب و قبل از ارسال کالا، کل وجه به حساب بانکی خریدار مسترد خواهد شد.
          <br />
          الف -۲ ) بعد از ارسال کالا: مشتری حق انصراف و مرجوع نمودن کالای
          خریداری شده را از تاریخ تسلیم کالا نداشته و به محض استفاده از حق
          انصراف قبل از تحویل محصول از ماموران حمل، فروشگاه اینترنتی رابط اتحاد
          بدون مطالبه هیچ گونه وجهی عین مبلغ دریافتی را در اسرع وقت مطابق با
          سیاست های واحد مالی، به شما مسترد خواهد کرد و تنها هزینه ی تحمیلی بر
          مشتری هزینه حمل و فرستادن کالا خواهد بود. (ماده ۳۷ قانون تجارت
          الکترونیک)
          <br />
          الف – ۳ ) فروشگاه اینترنتی رابط اتحاد برای کالاهای مرجوعی ( سلیقه ای )
          برای مشریان عزیز بازه زمانی ( ۷ روز ) را نسبت به کالای موردنظر تعیین
          کرده است.
          <br />
          <br />
          ب) گارانتی
          <br />
          مدت گارانتی و خدمات پس از فروش کلیه کالاهای عرضه شده در سایت برای هر
          کالا متفاوت بوده و در بخش توضیحات آن محصول درج شده است (در صورت عدم
          درج موارد مذکور در این قسمت، گارانتی برای آن کالا وجود ندارد) اکنون
          چنانچه کالای خریداری شده دارای گارانتی باشد و دچار نقص فنی شود، خریدار
          می بایست طبق شرایط مندرج در کارت گارانتی نسبت به ارائه کالا و دریافت
          خدمات پس از فروش از شرکت گارانتی دهنده اقدام نماید.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Rules;
