import React, { useState } from "react";
import "./Agent.scss";

import { useNavigate } from "react-router";

import $ from "jquery";

import { fetchApi, getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";

import agentBanner from "../../assets/images/agentBanner.png";
import aboutpic from "../../assets/images/aboutCompany.png";
import IranMap from "../../components/IranMap/IranMap";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function Agent() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [agent, setAgent] = useState([]);

  const history = useNavigate();

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  const cityNameHandler = (city) => {
    console.log(city);
    fetchApi(`api/agent/user/fetch_agent`, "", { city, page: 1 }).then(
      (res) => {
        console.log(res);
        setAgent(res.data);
      }
    );
  };

  let count = 0;

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-agent">
        <img src={agentBanner} alt="agentBanner" className="agentBanner" />
        <div className="agent-about">
          <div className="content">
            <h1>درباره شرکت</h1>
            <p>
              گروه تولیدی صنعتی اتحاد شرکت بهساز رابط اتحاد این شرکت بعنوان واحد جدید گروه تولیدی صنعتی اتحاد در سال١٣٩٩ و با هدف تولید بند رابط های الکتریکی و الکترونیکی تاسیس و آغاز بکارنمود.با توجه به نیاز و مصرف فراوان و عمومی کشور به بند رابط ها و کابل شارژر های موبایل-پرینتر-تبلت-کامپیوتر- لوازم صوتی و تصویری و محصولات همگن و علم به توان تولید داخلی این محصولات،شرکت بهساز رابط اتحاد با استعانت از ایزدمتعال و با تکیه به توان صنعتگران فنی مجموعه خود و پشتوانه٣٠ سال تولید مستمر و حضور موفق در بازار مصرفی بعنوان اولین تولیدکننده داخلی، محصولات خود را با تولید کابل شارژر موبایل های نوع Micro-Iphone- c-type پرینتر-کامپیوتر و لپ تاپ آغاز نمود است.
            </p>
          </div>
          <div className="image">
            <img src={aboutpic} alt="aboutpic" className="aboutpic" />
          </div>
        </div>
        <div className="map">
          <label htmlFor="" className="title">
            ابتدا استان خود را انتخاب کنید
          </label>
          <IranMap citynames={cityNameHandler} />
        </div>
        <div className="agent-list">
          <label htmlFor="">لیست نمایندگان</label>
          <table>
            <thead>
              <th>ردیف</th>
              <th>نام</th>
              <th>استان</th>
              <th>شماره موبایل</th>
            </thead>
            <hr />
            {agent
              ? agent.map((item, index) => {
                count++;
                return (
                  <>
                    <tr key={index}>
                      <td>{count}</td>
                      <td>{item.name}</td>
                      <td>{item.state}</td>
                      <td>{item.mobile}</td>
                    </tr>
                    <hr />
                  </>
                );
              })
              : null}
          </table>
        </div>
        <div className="submit-agent">
          <button onClick={(e) => history("/agentForm")}>ثبت نماینده</button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Agent;
