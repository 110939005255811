import React, { useState } from "react";
import "./Footer.scss";

import { ToastContainer, toast } from "react-toastify";

import downloadImg from "../../assets/images/sibApp.png";
import downloadImg1 from "../../assets/images/PWA.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

import $ from "jquery";

import Button from "../Button/Button";

import { fetchApi } from "../../defz";

function Footer() {
  const [email, setEmail] = useState("");

  return (
    <footer>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="top" />
      <div className="content">
        <div className="head">
          <div className="right">
            <section>
              <p className="title">لینک های اصلی</p>
              <Link to="/">صفحه اصلی</Link>
              <Link to="/basket">سبد خرید</Link>
              <Link to="/contactus">پشتیبانی</Link>
              <Link to="/aboutUs">درباره ما</Link>
            </section>
            <section>
              <p className="title">خدمات مشتریان</p>
              <Link to="/instagram">قرعه کشی</Link>
              <Link to="/warranty">پیگیری گارنتی</Link>
              <a
                href="https://blog.rabetettehadco.ir/"
                target={"_blank"}
                rel="noreferrer"
              >
                وبلاگ
              </a>
            </section>
            <section>
              <p className="title">راهنما</p>
              <Link to="/">نحوه ثبت سفارش</Link>
              <Link to="/rule">قوانین و مقررات</Link>
              <Link to="/privacy">سیاست های حریم شخصی</Link>
              <Link to="/">شیوه های پرداخت</Link>
            </section>
          </div>
          <div className="left">
            <p className="title">عضویت</p>
            <p className="tip">
              با عضویت در خبرنامه از آخرین اخبار و محصولات سایت مطلع شوید
            </p>

            <form action="">
              <input
                id="emailInput"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                title="عضویت"
                onClickAction={(e) => {
                  e.preventDefault();
                  if (email.length >= 7 && email.indexOf("@") > 0) {
                    $("#emailInput").css({ border: "1px solid #f5f5f5" });
                    fetchApi(`api/user/submit/Newsletters`, "", {
                      email: email,
                    }).then((res) => {
                      if (res.description === "done!") {
                        toast.success("با موفقیت عضو شدید", {
                          position: "bottom-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      } else if (res.status_code === 402) {
                        toast.error(res.description_fa, {
                          position: "bottom-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                  } else {
                    $("#emailInput").css({ border: "1px solid red" });
                  }
                }}
              />
            </form>
          </div>
        </div>
        <div className="banner">
          <div className="right">
            <div className="logo" />
            <p className="title">دانلود اپلیکیشن رابط اتحاد</p>
          </div>
          <div className="left">
            <a
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.rabetettehad&gl=GB"
              target={"_blank"}
              className="link google-play"
            ></a>
            <a
              rel="noreferrer"
              href="https://cafebazaar.ir/app/com.rabetettehad"
              className="link bazzar"
              target={"_blank"}
            ></a>

            <a
              rel="noreferrer"
              href="https://rabetettehadco.ir/download/rabet-ettehad.apk"
              target={"_blank"}
            >
              <img
                src={downloadImg}
                alt="downloadImg"
                className="downloadImg"
              />
            </a>

            <Link to="/pwa" className="pwa">
              <img src={downloadImg1} className="" alt="" />
            </Link>
          </div>
        </div>
        <div className="info">
          <div className="right">
            <p className="title">رابط اتحاد</p>
            <p className="description">
              رابط اتحاد به عنوان یکی از قدیمی‌ترین فروشگاه های اینترنتی با بیش
              از یک دهه تجربه، با پایبندی به سه اصل، پرداخت در محل، ۷ روز ضمانت
              بازگشت کالا و تـضمین اصـل ‌بودن کالا موفق شده تا همگام با
              فروشگاه‌های معتبر جهان، به بزرگ‌ترین فروشگاه اینترنتی ایران تبدیل
              شود. به محض ورود به سایت رابط اتحاد با دنیایی از کالا رو به رو
              می‌شوید! هر آنچه که نیاز دارید و به ذهن شما خطور می‌کند در اینجا
              پیدا خواهید کرد
            </p>
          </div>
          <div className="left">
            <a
              referrerPolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=256896&amp;Code=XU07trHgTDpkcQZI7DaK"
            >
              <img
                referrerPolicy="origin"
                src="https://Trustseal.eNamad.ir/logo.aspx?id=256896&amp;Code=XU07trHgTDpkcQZI7DaK"
                alt=""
                style={{ cursor: "pointer", width: "87px" }}
                id="XU07trHgTDpkcQZI7DaK"
              />
            </a>
          </div>
        </div>
        <div className="copy-right">
          <div>
            استفاده از مطالب فروشگاه اینترنتی رابط اتحاد فقط برای مقاصد غیرتجاری
            و با ذکر منبع بلامانع است. طراحی و توسعه توسط{" "}
            <a target="_blank" href="https://platinco.ir">
              پلاتین
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
