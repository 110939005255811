import React, { useState } from "react";
import "./BasketForm.scss";

import { useNavigate } from "react-router-dom";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import { Provinces, fetchApi } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleNotch);

function BaksetFrom() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [city, setCity] = useState([]);
  const [payMethod, setPayMethod] = useState("onlion");

  const history = useNavigate();

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
      history("/basketFormV");
    }
  };

  const validationSchema = yup
    .object({
      state: yup.string().required().required("استان انتخاب شود"),
      city: yup.string().required().required("شهر انتخاب شود"),
      name: yup
        .string()
        .min(3, "حداقل 3 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد نام خالی است"),
      email: yup
        .string()
        .min(8, "حداقل 8 کاراکتر")
        .max(25, "حداکثر 25 کارکتر")
        .required("فیلد آدرس ایمیل خالی است"),
      address: yup
        .string()
        .min(10, "حداقل 10 کاراکتر")
        .max(60, "حداکثر 60 کارکتر")
        .required("فیلد آدرس خالی است"),
      mobile: yup
        .string()
        .min(11, "حداقل 11 کاراکتر")
        .max(11, "حداکثر 11 کارکتر")
        .required("فیلد شماره موبایل خالی است"),
      postCode: yup
        .string()
        .min(10, "حداقل 10 کاراکتر")
        .max(10, "حداکثر 10 کارکتر")
        .required("فیلد کد پستی خالی است"),
      description: yup.string().default(),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    $(".buttonText").css({ display: "none" });
    $(".buttonSpin").css({ display: "block" });

    fetchApi(`/api/user/send_v_code/phone_number=${data.mobile}`, "").then(
      (res) => {
        if (res.description === "Verification SMS has been sent!") {
          $(".cover").css({ display: "block" });
          setPassword(true);
        }
      }
    );
  };

  const stateHanlder = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let option = optionElement.getAttribute("data-id");

    setCity([]);

    fetchApi(`/api/mid/fetch/city/id=${option}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((respone) => setCity(respone.data));
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-basketForm">
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <div className="title">جزئیات پرداخت</div>
          <div className="row">
            <div className="col">
              <label htmlFor="">نام و نام خانوادگی</label>
              <input
                type="text"
                name="name"
                id="name"
                className={`${errors.name ? "is-invalid" : ""}`}
                {...register("name")}
              />
              <small className="allert">{errors.name?.message}</small>
            </div>
            <div className="col">
              <label htmlFor="">شماره موبایل</label>
              <input
                type="number"
                name="mobile"
                id="mobile"
                className={`${errors.mobile ? "is-invalid" : ""}`}
                {...register("mobile")}
              />
              <small className="allert">{errors.mobile?.message}</small>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="">استان</label>
              <select
                name="state"
                id="state"
                className={`${errors.state ? "is-invalid" : ""}`}
                {...register("state")}
                onChange={stateHanlder}
              >
                <option value="">استان</option>
                {Provinces.map((state) => {
                  return (
                    <option value={state.title} data-id={state._id}>
                      {state.title}
                    </option>
                  );
                })}
              </select>
              <small className="allert">{errors.state?.message}</small>
            </div>
            <div className="col">
              <label htmlFor="">شهر</label>
              <select
                name="city"
                id="city"
                className={`${errors.city ? "is-invalid" : ""}`}
                {...register("city")}
              >
                <option value="">شهر</option>
                {city
                  ? city.map((city) => {
                      return (
                        <option value={city.title} data-id={city._id}>
                          {city.title}
                        </option>
                      );
                    })
                  : null}
              </select>
              <small className="allert">{errors.city?.message}</small>
            </div>
          </div>
          <div className="row">
            <div className="col-100">
              <label htmlFor="">ادامه آدرس</label>
              <input
                type="text"
                name="address"
                id="address"
                className={`${errors.address ? "is-invalid" : ""}`}
                {...register("address")}
              />
              <small className="allert">{errors.address?.message}</small>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="">کد پستی</label>
              <input
                type="number"
                name="postCode"
                id="postCode"
                className={`${errors.postCode ? "is-invalid" : ""}`}
                {...register("postCode")}
              />
              <small className="allert">{errors.postCode?.message}</small>
            </div>
            <div className="col">
              <label htmlFor="">آدرس ایمیل</label>
              <input
                type="email"
                name="email"
                id="email"
                className={`${errors.email ? "is-invalid" : ""}`}
                {...register("email")}
              />
              <small className="allert">{errors.email?.message}</small>
            </div>
          </div>
          <div className="row">
            <div className="col-100">
              <label htmlFor="">توضیحات</label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="10"
                className={`${errors.description ? "is-invalid" : ""}`}
                {...register("description")}
              ></textarea>
            </div>
          </div>
          <div className="title">روش پرداخت</div>
          <div className="row-pay">
            <div className="title">لطفا روش پرداخت خود را مشخص کنید</div>
            <div className="way-pay">
              <input
                type="radio"
                onClick={() => {
                  setPayMethod("onlion");
                }}
                name="pay"
                id=""
                defaultChecked
              />{" "}
              پرداخت آنلاین
            </div>
            <div className="way-pay">
              <input
                type="radio"
                name="pay"
                id=""
                onClick={() => {
                  setPayMethod("delivery");
                }}
              />{" "}
              پرداخت هنگام دریافت
            </div>
          </div>
          <div className="row">
            <div className="col-rule">
              <input type="radio" name="" id="" defaultChecked />
              من قوانین و مقررات شرایط و مقررات را خواندم و آن را می پذیرم
            </div>
            <div className="col">
              <button>
                <span className="buttonText">ثبت سفارش</span>
                <span className="buttonSpin">
                  <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default BaksetFrom;
