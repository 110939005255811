import React, { useState } from "react";
import "./ContactUs.scss";

import $ from "jquery";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import banner from "../../assets/images/banner.png";

import Map from "../../components/Map/Map";

import { fetchApi, getProductCountBasket } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleNotch);

function ContactUs({ token }) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  const validationSchema = yup
    .object({
      name: yup
        .string()
        .min(3, "حداقل 3 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد نام خالی است"),
      email: yup
        .string()
        .min(3, "حداقل 3 کاراکتر")
        .max(50, "حداکثر 50 کارکتر")
        .required("فیلد ایمیل خالی است"),
      subject: yup
        .string()
        .min(3, "حداقل 3 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد موضوع خالی است"),
      msg: yup
        .string()
        .min(10, "حداقل 10 کاراکتر")
        .max(500, "حداکثر 500 کارکتر")
        .required("فیلد پیغام خالی است"),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    $(".buttonTextAgent").css({ display: "none" });
    $(".buttonSpinAgent").css({ display: "block" });
    e.preventDefault();

    fetchApi(`api/user/submit/contac`, token, data).then((res) => {
      if (res.description === "submited successfully!") {
        toast.success("پیغام شما ارسال شد", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        $("#contactSubmit").css({ display: "none" });
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header handler={signInHandler} menuHanlder={mobileMenuHandler} basketHandler={getProductCountBasket()} />
      <div className="index-contactUs">
        <div className="banner">
          <img src={banner} alt="banner" />
        </div>
        <div className="information-work">
          <div className="col-form">
            <form action="" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <label htmlFor="">نام و نام خانوادگی</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className={`${errors.name ? "is-invalid" : ""}`}
                    {...register("name")}
                  />
                  <small className="allert">{errors.name?.message}</small>
                </div>
                <div className="col">
                  <label htmlFor="">موضوع</label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    className={`${errors.subject ? "is-invalid" : ""}`}
                    {...register("subject")}
                  />
                  <small className="allert">{errors.subject?.message}</small>
                </div>
              </div>
              <div className="col-100">
                <label htmlFor="">ایمیل</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`${errors.email ? "is-invalid" : ""}`}
                  {...register("email")}
                />
                <small className="allert">{errors.email?.message}</small>
              </div>
              <div className="col-100">
                <label htmlFor="">پیام</label>
                <textarea
                  name="msg"
                  id="msg"
                  cols="30"
                  rows="10"
                  className={`${errors.msg ? "is-invalid" : ""}`}
                  {...register("msg")}
                ></textarea>
                <small className="allert">{errors.msg?.message}</small>
              </div>
              <div className="col-100">
                <button id="contactSubmit">
                  <span className="buttonTextAgent">ارسال</span>
                  <span className="buttonSpinAgent">
                    <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div className="col-information">
            <div className="work-time">
              <span>ساعات کاری </span>
              <br />
              <span className="content">7 صبح تا 4 عصر</span>
            </div>
            <div className="work-time">
              <span>آدرس </span>
              <br />
              <span className="content"> تبریز ، جاده تهران روبروی داروسازی دانا کوی انرژی ماشین شرکت بهساز رابط اتحاد </span>
            </div>
            <div className="work-time">
              <span>ایمیل </span>
              <br />
              <span className="content">info@rabetettehadco.ir</span>
            </div>
          </div>
        </div>
        <div className="col-map">
          <Map />
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
