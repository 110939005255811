import React, { useState, useEffect } from "react";
import "./AgentForm.scss";

import $ from "jquery";

import { DatePicker } from "react-persian-datepicker";
import moment from "jalali-moment";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { fetchApi, getProductCountBasket, Provinces } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleNotch);

function AgentForm({ token }) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [date, setDate] = useState("");
  const [dateValidate, setDateValidate] = useState(false);
  const [city, setCity] = useState([]);

  useEffect(() => {
    fetchApi(`/api/user/validate`, token, "").then((res) => {
      if (res.status_code === 200) {
      } else if (res.description === "Access denied!") {
        toast.info("ابتدا وارد سایت شوید", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 4000);
      }
    });
  });

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  const validationSchema = yup
    .object({
      state: yup.string().required().required("استان انتخاب شود"),
      city: yup.string().required().required("شهر انتخاب شود"),
      name: yup
        .string()
        .min(3, "حداقل 3 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد نام خالی است"),
      fatherName: yup
        .string()
        .min(3, "حداقل 3 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد نام پدر خالی است"),
      meliCode: yup
        .string()
        .min(10, "حداقل 10 کاراکتر")
        .max(10, "حداکثر 10 کارکتر")
        .required("فیلد کد ملی خالی است"),
      education: yup
        .string()
        .min(4, "حداقل 4 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد میزان تحصیلات خالی است"),
      email: yup
        .string()
        .min(8, "حداقل 8 کاراکتر")
        .max(25, "حداکثر 25 کارکتر")
        .required("فیلد آدرس ایمیل خالی است"),
      address: yup
        .string()
        .min(10, "حداقل 10 کاراکتر")
        .max(60, "حداکثر 60 کارکتر")
        .required("فیلد آدرس خالی است"),
      tell: yup
        .string()
        .min(11, "حداقل 11 کاراکتر")
        .max(11, "حداکثر 11 کارکتر")
        .required("فیلد شماره تلفن خالی است"),
      mobile: yup
        .string()
        .min(11, "حداقل 11 کاراکتر")
        .max(11, "حداکثر 11 کارکتر")
        .required("فیلد شماره موبایل خالی است"),
      shopAddress: yup
        .string()
        .min(10, "حداقل 10 کاراکتر")
        .max(60, "حداکثر 60 کارکتر")
        .required("فیلد آدرس خالی است"),
      shopType: yup
        .string()
        .min(4, "حداقل 4 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد نوع مالکیت خالی است"),
      shopArea: yup
        .string()
        .min(2, "حداقل 2 کاراکتر")
        .max(4, "حداکثر 4 کارکتر")
        .required("فیلد مساحت خالی است"),
      certificate: yup
        .string()
        .min(4, "حداقل 4 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد نوع جواز خالی است"),
      money: yup
        .string()
        .min(4, "حداقل 4 کاراکتر")
        .max(25, "حداکثر 25 کارکتر")
        .required("فیلد سرمایه نقدی خالی است"),
      description: yup.string().default(),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    $(".buttonTextAgent").css({ display: "none" });
    $(".buttonSpinAgent").css({ display: "block" });
    data.date = date;
    if (dateValidate) {
      $(".birthdayInput").css({ border: "1px solid #d6d6d6" });

      fetchApi("/api/agent/user/add_agent", token, data).then((res) => {
        if (res.description === "you submited work!") {
          toast.warn("شما قبلا نمایندگی خود را ثبت کرده اید", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $(".buttonTextAgent").css({ display: "block" });
          $(".buttonSpinAgent").css({ display: "none" });
        } else if (res.description === "done!") {
          toast.success("نمایندگی با موفقیت ثبت شد", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $("#agentSubmit").css({ display: "none" });
        }
      });
    } else {
      $(".birthdayInput").css({ border: "1px solid red" });
      $(".buttonTextAgent").css({ display: "block" });
      $(".buttonSpinAgent").css({ display: "none" });
      window.scroll({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
    }
  };

  const stateHanlder = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let option = optionElement.getAttribute("data-id");

    setCity([]);

    fetchApi(`/api/mid/fetch/city/id=${option}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((respone) => setCity(respone.data))
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-basketForm">
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="title">فرم ثبت نماینده</div>
            <div className="row">
              <div className="col">
                <label htmlFor="">استان</label>
                <select
                  name="state"
                  id="state"
                  className={`${errors.state ? "is-invalid" : ""}`}
                  {...register("state")}
                  onChange={stateHanlder}
                >
                  <option value="">استان</option>
                  {Provinces.map((state) => {
                    return (
                      <option value={state.title} data-id={state._id}>
                        {state.title}
                      </option>
                    );
                  })}
                </select>
                <small className="allert">{errors.state?.message}</small>
              </div>
              <div className="col">
                <label htmlFor="">شهر</label>
                <select
                  name="city"
                  id="city"
                  className={`${errors.city ? "is-invalid" : ""}`}
                  {...register("city")}
                >
                  <option value="">شهر</option>
                  {city
                    ? city.map((city) => {
                        return (
                          <option value={city.title} data-id={city._id}>
                            {city.title}
                          </option>
                        );
                      })
                    : null}
                </select>
                <small className="allert">{errors.city?.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="">نام و نام خانوادگی</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className={`${errors.name ? "is-invalid" : ""}`}
                  {...register("name")}
                />
                <small className="allert">{errors.name?.message}</small>
              </div>
              <div className="col">
                <label htmlFor="">نام پدر</label>
                <input
                  type="text"
                  name="fatherName"
                  id="fatherName"
                  className={`${errors.fatherName ? "is-invalid" : ""}`}
                  {...register("fatherName")}
                />
                <small className="allert">{errors.fatherName?.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="">کد ملی</label>
                <input
                  type="number"
                  name="meliCode"
                  id="meliCode"
                  className={`${errors.meliCode ? "is-invalid" : ""}`}
                  {...register("meliCode")}
                />
                <small className="allert">{errors.meliCode?.message}</small>
              </div>
              <div className="col">
                <label htmlFor="">تاریخ تولد</label>
                <DatePicker
                  className="birthdayInput"
                  onChange={(value) => {
                    setDateValidate(true);
                    setDate(
                      moment(value._d)
                        .locale("fa")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="">میزان تحصیلات</label>
                <input
                  type="text"
                  name="education"
                  id="education"
                  className={`${errors.education ? "is-invalid" : ""}`}
                  {...register("education")}
                />
                <small className="allert">{errors.education?.message}</small>
              </div>
              <div className="col">
                <label htmlFor="">ایمیل</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`${errors.email ? "is-invalid" : ""}`}
                  {...register("email")}
                />
                <small className="allert">{errors.email?.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col-100">
                <label htmlFor="">آدرس محل سکونت</label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className={`${errors.address ? "is-invalid" : ""}`}
                  {...register("address")}
                />
                <small className="allert">{errors.address?.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="">شماره تلفن ثابت</label>
                <input
                  type="number"
                  name="tell"
                  id="tell"
                  className={`${errors.tell ? "is-invalid" : ""}`}
                  {...register("tell")}
                />
                <small className="allert">{errors.tell?.message}</small>
              </div>
              <div className="col">
                <label htmlFor="">شماره تلفن همراه</label>
                <input
                  type="number"
                  name="mobile"
                  id="mobile"
                  className={`${errors.mobile ? "is-invalid" : ""}`}
                  {...register("mobile")}
                />
                <small className="allert">{errors.mobile?.message}</small>
              </div>
            </div>
            <div className="title">مشخصات فروشگاه</div>
            <div className="row">
              <div className="col-100">
                <label htmlFor="">آدرس محل فروشگاه</label>
                <input
                  type="text"
                  name="shopAddress"
                  id="shopAddress"
                  className={`${errors.shopAddress ? "is-invalid" : ""}`}
                  {...register("shopAddress")}
                />
                <small className="allert">{errors.shopAddress?.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="">نوع مالکیت</label>
                <input
                  type="text"
                  name="shopType"
                  id="shopType"
                  className={`${errors.shopType ? "is-invalid" : ""}`}
                  {...register("shopType")}
                />
                <small className="allert">{errors.shopType?.message}</small>
              </div>
              <div className="col">
                <label htmlFor="">مساحت</label>
                <input
                  type="number"
                  name="shopArea"
                  id="shopArea"
                  className={`${errors.shopArea ? "is-invalid" : ""}`}
                  {...register("shopArea")}
                />
                <small className="allert">{errors.shopArea?.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="">نوع جواز</label>
                <input
                  type="text"
                  name="certificate"
                  id="certificate"
                  className={`${errors.certificate ? "is-invalid" : ""}`}
                  {...register("certificate")}
                />
                <small className="allert">{errors.certificate?.message}</small>
              </div>
              <div className="col">
                <label htmlFor="">سرمایه نقدی</label>
                <input
                  type="text"
                  name="money"
                  id="money"
                  className={`${errors.money ? "is-invalid" : ""}`}
                  {...register("money")}
                />
                <small className="allert">{errors.money?.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col-100">
                <label htmlFor="">توضیحات</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  className={`${errors.description ? "is-invalid" : ""}`}
                  {...register("description")}
                ></textarea>
                <small className="allert">{errors.description?.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col-rule">
                <input type="radio" name="" id="" defaultChecked />
                من قوانین و مقررات شرایط و مقررات را خواندم و آن را می پذیرم
              </div>
              <div className="col">
                <button id="agentSubmit">
                  <span className="buttonTextAgent">ثبت نماینده</span>
                  <span className="buttonSpinAgent">
                    <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AgentForm);
