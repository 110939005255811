import React, { useState, useEffect } from "react";
import "./Product.scss";

import { useParams, Link, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import $ from "jquery";

import { Rating } from "react-simple-star-rating";

import productPic from "../../assets/images/product-pic.png";
import logo from "../../assets/svg/logo.svg";
import Garanty from "../../assets/svg/garanty.svg";
import Payment from "../../assets/svg/Payment.svg";
import Support from "../../assets/svg/Support.svg";
import Delivery from "../../assets/svg/Delivery.svg";

import { sliceNumber, fetchApi, getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import Fancybox from "../../components/Fancybox/Fancybox";
import { Blocks } from "react-loader-spinner";

function Product({ token }) {

  const navigate = useNavigate()

  const [loader, setLoader] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [basketCount, setBasketCount] = useState(0);
  const [userType, setUserType] = useState("");
  const [img, setImg] = useState("");
  const [product, setProduct] = useState("");
  const [star, setStar] = useState(100);
  const [starRate, setStarRate] = useState(100);
  const [state, setState] = useState("noComment");
  const [openion, setOpenion] = useState("");
  const [cats, setCats] = useState()
  const [catId, setCatId] = useState()
  const { id } = useParams();

  useEffect(() => {
    fetchApi(`/api/user/shop/fetch_one`, "", {
      id,
    }).then((res) => {
      console.log(res);
      setLoader(false)
      setCats(res.data[0].cats)
      setProduct(res.data[0]);
      setImg(res.data[0].pics[0]);
      setStar(res.data[0].scoore);
      setCatId(res.data[0].category)
    });

    fetchApi(`/api/user/validate`, token, "").then((res) => {
      setUserType(res.type);
    });

    fetchApi(`/api/user/shop/fetch_one_product_comments`, "", {
      productid: id,
    }).then((res) => {
      setOpenion(res.data);
    });

    setBasketCount(getProductCountBasket());

    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [id, basketCount, token]);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const forgetPasswordHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(false);
    setPassword(true);
  };

  // const [rating, setRating] = useState(0);

  const commentHandler = (e) => {
    if (e.target.id === "details") {
      $(".details").css({ display: "block" });
      $(".comments").css({ display: "none" });
      $(".payWay").css({ display: "none" });
      $("#details").addClass("active");
      $("#comment").removeClass("active");
      $("#pay").removeClass("active");
    } else if (e.target.id === "comment") {
      $(".details").css({ display: "none" });
      $(".comments").css({ display: "block" });
      $(".payWay").css({ display: "none" });
      $("#details").removeClass("active");
      $("#comment").addClass("active");
      $("#pay").removeClass("active");
    } else if (e.target.id === "pay") {
      $(".details").css({ display: "none" });
      $(".comments").css({ display: "none" });
      $(".payWay").css({ display: "block" });
      $("#details").removeClass("active");
      $("#comment").removeClass("active");
      $("#pay").addClass("active");
    }
  };

  const rejectCommend = (e) => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
  };
  const commentbody = {
    productid: id,
    title: $('#openion').val(),
    dis: $('#message').val(),
    score: starRate,
    state: state,
  }
  const addComment = () => {
    fetchApi('/en/api/user/shop/send_comment_product', token, commentbody).then((res) => {
      console.log(res)
    });
  }
  const addCommentHandler = (e) => {
    $(".cover").css({ display: "flex" });
    $(".submitComment").css({ display: "block" });
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  //get basket
  let basket = localStorage.getItem("basket");

  //if not exist basket or exist in localstorage
  if (basket === null) {
    basket = [];
  } else {
    basket = JSON.parse(basket);
  }

  //add basket button handler
  const addToBasket = (e) => {
    //basket item variable
    let itemBasket = {};

    //get product id
    let id = e.target.id;

    //check index product varable
    let indexProduct;

    //get index product in localstorage
    basket.forEach((item, index) => {
      item.id === id ? (indexProduct = index) : (indexProduct = false);
    });

    //upper count product or add product to list
    if (indexProduct !== false && indexProduct !== undefined) {
      let count = basket[indexProduct].count;
      basket[indexProduct].count = count + 1;
      toast.success("به سبد خرید افزوده شد", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (id.length > 0) {
        itemBasket = { id: id, count: 1 };
        basket.push(itemBasket);
        toast.success("به سبد خرید افزوده شد", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    $(".payButton").css({ backgroundColor: "green" });

    setTimeout(() => {
      $(".payButton").css({ backgroundColor: "#f6931e" });
    }, 3000);
    //save in localstorage
    localStorage.setItem("basket", JSON.stringify(basket));
    setBasketCount(getProductCountBasket());
  };

  const validationSchema = yup
    .object({
      title: yup
        .string()
        .min(3, "حداقل 3 کاراکتر")
        .max(20, "حداکثر 20 کارکتر")
        .required("فیلد نام خالی است"),
      message: yup
        .string()
        .min(10, "حداقل 10 کاراکتر")
        .max(100, "حداکثر 100 کارکتر")
        .required("فیلد نام خالی است"),
      description: yup.string().default(),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleRating = (rate) => {
    setStarRate(rate);
  };

  const suggestHandler = (e) => {
    setState(e.target.id);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    fetchApi(`/api/user/shop/send_comment_product`, token, {
      score: starRate,
      title: data.title,
      dis: data.message,
      state,
      productid: product._id,
    }).then((res) => {
      if (res.description === "done!") {
        $(".cover").css({ display: "none" });
        $(".submitComment").css({ display: "none" });
        toast.success("نظر شما ثبت شد", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {
        loader &&
        <div className="loader">
          <Blocks
            visible={true}
            height="120"
            width="120"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        </div>
      }
      <div className="cover" onClick={rejectCoverHandler}></div>
      <form className="submitComment" onSubmit={handleSubmit(onSubmit)}>
        <section className="title">افزودن نظر</section>
        <section className="point">
          <span>امتیاز شما به این کالا</span>
          <span>
            <Rating
              initialValue={5}
              size={20}
              showTooltip={false}
              onClick={handleRating}
            />
          </span>
        </section>
        <hr />
        <section className="openion">
          <label htmlFor="openion">عنوان نظر(اجباری)</label>
          <input
            id="openion"
            type="text"
            name="name"
            className={`${errors.title ? "is-invalid" : ""}`}
            {...register("title")}
          />
          <small className="allert">{errors.title?.message}</small>
        </section>
        <section className="openion">
          <label htmlFor="message">متن نظر(اجباری)</label>
          <textarea
            name=""
            id="message"
            cols="20"
            rows="5"
            className={`${errors.message ? "is-invalid" : ""}`}
            {...register("message")}
          ></textarea>
          <small className="allert">{errors.message?.message}</small>
        </section>
        <section className="suggest" onClick={suggestHandler}>
          <span>خرید این محصول را</span>
          <span>
            <input type="radio" name="suggest" id="suggest" />
            پیشنهاد میکنم
          </span>
          <span>
            <input type="radio" name="suggest" id="noSuggest" />
            پیشنهاد نمیکنم
          </span>
          <span>
            <input type="radio" name="suggest" id="noComment" defaultChecked />
            نظری ندارم
          </span>
        </section>
        <section className="rule">
          <input type="radio" name="" id="" defaultChecked />
          <Link to="/rule">
            شرایط و قوانین سایت رابط اتحاد را برای ثبت نظر می‌پذیرم
          </Link>
        </section>
        <section className="buttons">
          <button onClick={addComment}>افزودن نظر جدید</button>
          <button onClick={rejectCommend}>انصراف</button>
        </section>
      </form>

      {sigIn ? <SignIn forgetHandler={forgetPasswordHandler} /> : null}
      {password ? <ForgetPassword signInHandler={signInHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}

      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={basketCount}
      />
      <main className="index-product">
        <div className="map"><span onClick={() => navigate("/")}>خانه</span> / محصولات / <span onClick={() => navigate(`/filter/${catId}`)}>{cats}</span></div>
        <div className="product">
          <div className="img">
            <Fancybox>
              <a data-fancybox="gallery" href={img}>
                <img src={product ? img : productPic} alt="productPic" />
              </a>
            </Fancybox>
          </div>
          <div className="content">
            <h4 className="title">
              {product ? product.title : "عنوان محصول"}
            </h4>
            <h5 className="subTitle">{product.subtitle}</h5>
            <hr />
          </div>
          <div className="price-section">
            <section className="mark">
              <img src={logo} alt="logo" />
              <div className="title">
                <h3>فروشگاه اینترنتی رابط اتحاد</h3>
                <h4>Rabet Ettehad</h4>
              </div>
            </section>
            <div className="content">
              <section className="point">
                <span>5/{star}</span>
                <span>
                  <Rating initialValue={star} size={20} showTooltip={false} />
                </span>
              </section>
              <hr />
              <section className="price">
                <h3>قیمت کالا</h3>
                <div className="self">
                  <span
                    className="main-price"
                    style={
                      product.priceagentoff > 0 || product.priceoff > 0
                        ? {}
                        : {
                          textDecoration: "auto",
                          color: "#1D4C61",
                          opacity: "1",
                        }
                    }
                  >
                    {userType === "agent"
                      ? product.priceagent
                        ? sliceNumber(product.priceagent + " تومان ")
                        : null
                      : product.price
                        ? sliceNumber(product.price + " تومان ")
                        : null}
                  </span>
                  <span
                    className="percent"
                    style={
                      product.priceagentoff > 0 || product.priceoff > 0
                        ? {}
                        : { display: "none" }
                    }
                  >
                    {product ? parseInt(product.per) + "%" : null}
                  </span>
                </div>
                <div className="off">
                  <span
                    className="price-off"
                    style={
                      product.priceagentoff > 0 || product.priceoff > 0
                        ? {}
                        : { display: "none" }
                    }
                  >
                    {userType === "agent"
                      ? product.priceagentoff
                        ? sliceNumber(product.priceagentoff)
                        : null
                      : product.priceoff
                        ? sliceNumber(product.priceoff)
                        : null}
                    تومان
                  </span>
                </div>
              </section>
              <hr />
              {product ? (
                product.Producttotal !== 0 ? (
                  <button
                    className="payButton"
                    onClick={addToBasket}
                    id={product._id}
                  >
                    افزودن به سبد خرید
                  </button>
                ) : (
                  <button
                    className="payButton"
                    disabled
                    style={{ backgroundColor: "gray" }}
                  >
                    محصول موجود نیست
                  </button>
                )
              ) : null}
            </div>
          </div>
        </div>

        <div className="product">
          <div className="thumbnails">
            <Fancybox>
              <p>
                {product
                  ? product.pics.map((pic, index) => {
                    return (
                      <a data-fancybox="gallery" href={pic} key={index}>
                        <img src={pic} alt={index} />
                      </a>
                    );
                  })
                  : null}
              </p>
            </Fancybox>
          </div>
          <div className="support">
            <img src={Delivery} alt="" />
            <img src={Support} alt="" />
            <img src={Payment} alt="" className="payment-icon" />
            <img src={Garanty} alt="" />
          </div>
        </div>

        <div className="comment">
          <div className="labels" onClick={commentHandler}>
            <section className="label active" id="details">
              توضیحات محصول
            </section>
            <section className="label" id="comment">
              نظرات
            </section>
            <section className="label" id="pay">
              نحوه پرداخت
            </section>
          </div>
          <div className="details">{product.details}</div>
          <div className="comments">
            <section className="add-comment">
              <div className="content">
                <h4>چطور می‌توانم در مورد این کالا نظر بدهم؟</h4>
                <span>
                  اگر این محصول را از رابط اتحاد خریداری کرده‌اید ، می‌توانید با
                  زدن بر روی دکمه ، نظر خود ثبت کنید
                </span>
              </div>
              <button onClick={addCommentHandler}>افزودن نظر جدید</button>
            </section>
            <hr />
            {openion
              ? openion.map((openion, index) => {
                return (
                  <section className="comment" key={index}>
                    <div className="row">
                      <div className="content">
                        <span>
                          <Rating
                            initialValue={openion.score}
                            size={20}
                            showTooltip={false}
                          />
                        </span>
                        <span>{openion.coment}</span>
                      </div>
                      {openion.state === "suggest" ? (
                        <div className="status-success">
                          خرید این محصول را توصیه میکنم
                        </div>
                      ) : (
                        ""
                      )}
                      {openion.state === "noSuggest" ? (
                        <div className="status-danger">
                          خرید این محصول را توصیه نمیکنم
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">{openion.dis}</div>
                    <hr />
                  </section>
                );
              })
              : null}
          </div>
          <div className="payWay">
            <b>پرداخت در محل</b>
            <br />
            امکان انتخاب شیوه ی پرداخت در محل با درنظر گرفتن شرایط سفارش، به
            صورت هوشمند توسط سیستم بررسی خواهد شد و در صورت امکان انتخاب این
            شیوه، شما می توانید مبلغ سفارش خود را به هنگام تحویل کالا، با کلیه
            کارت های بانکی از طریق دستگاه کارتخوان پرداخت کنید. همچنین چنانچه
            سفارش شما دارای شرایط زیر باشد امکان پرداخت در محل وجود نخواهد داشت.
            <br />
            <br />
            <b>پرداخت اینترنتی</b>
            <br />
            کاربران رابط اتحاد می‌توانند در هنگام ثبت سفارش، از طریق درگاه
            اینترنتی، هزینه سفارش خود را به صورت آنلاین پرداخت کنند. پرداخت موفق
            هزینه به منزله ثبت قطعی این پرداخت برای سفارش است و پس از آن پیامکی
            مبنی بر ثبت موفق سفارش، برای کاربر ارسال می‌شود و نیازی به اطلاع‌
            دادن پرداخت سفارش نیست و سفارش به صورت خودکار وارد مراحل آماده‌سازی
            و ارسال می‌شود.
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
