import React, { useState, useEffect } from "react";
import "./About.scss";

import $ from "jquery";

import { getProductCountBasket, fetchApi } from "../../defz";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../../../node_modules/swiper/swiper.scss";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";

import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function AboutUs() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [content, setContent] = useState("");

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  useEffect(() => {
    fetchApi(`api/page/user/fetch_page_about`, "", "").then((res) => {
      setContent(res.data);
    });
  }, []);

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-aboutUs">
        <div className="agent-about">
          <div
            className="content"
            style={{width: '100%'}}
            dangerouslySetInnerHTML={{ __html: content.html }}
          ></div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
