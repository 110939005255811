import React from 'react'
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import Shop from "../../components/Shop/Shop"
export default function AgentLanfing() {
    return (
        <div>
            <Header />
            <Shop />
            <Footer />
        </div>
    )
}
