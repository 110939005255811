import React, { useState } from 'react'
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

import "./GarantySubmit.scss"
import { fetchApi } from '../../defz';
import { connect } from 'react-redux';
import { selectUserToken } from '../../redux/Auth/userSelector';
import toast, { Toaster } from 'react-hot-toast';

import { MdOutlineFlashlightOff, MdOutlineFlashlightOn } from 'react-icons/md';
import { Blocks } from 'react-loader-spinner';

function GarantySubmit({ token }) {

    const [data, setData] = useState({})
    const [qr, setQr] = useState("")
    const [modalState, setModalState] = useState(false)
    const [torchState, setTorchState] = useState(false)
    const [stopScan, setStopScan] = useState(false)
    const [loader, setLoader] = useState(false)

    const handleGetQrData = (qrId) => {
        if (!qrId) return
        setLoader(true)
        fetchApi("api/qrcode/get_qr_data", token, { qr_id: qrId })
            .then((res) => {
                setLoader(false)
                console.log(res);
                if (res.status_code === 400) {
                    return toast.error("مشکلی پیش آمده", { duration: 2000 })
                }
                setModalState(true)
                setData(res)
            })
    }

    const handleSubmitQr = () => {
        setLoader(true)
        fetchApi("api/qrcode/submit_qr", token, { qr_id: qr })
            .then((res) => {
                setLoader(false)
                if (res.status_code === 200) {
                    toast.success("گارانتی با موفقیت ثبت شد", { duration: 2000 })
                }
                else if (res.status_code === 402) {
                    return toast.error(res.description_fa, { duration: 2000 })
                }
            })
    }

    const dashReplacer = (data) => {
        return data.replace("-", "<br /> -")
    }

    return (
        <div className='garantySubmit'>
            <Toaster position='top-center' />
            {
                modalState &&
                <div className="modalContainer">
                    <div className="modalSelf">
                        <p>نام محصول: <span>{data?.product?.title}</span></p>
                        <p>زیر عنوان محصول: <span>{data?.product?.subtitle}</span></p>
                        <img src={data?.product?.pics[0]} alt="" />
                        <p>توضیحات:</p>
                        <p>{data?.product?.details}</p>
                        {/* <p dangerouslySetInnerHTML={{ __html: dashReplacer(data?.product?.details) }} /> */}
                        <hr />
                        <p>تاریخ شروع گارانتی: <span>{data?.start_date}</span></p>
                        <p>تاریخ پایان گارانتی: <span>{data?.end_date}</span></p>
                    </div>
                    <div className="actions">
                        <button
                            onClick={() => {
                                setModalState(false)
                            }}
                        >
                            انصراف
                        </button>
                        <button className="submit" onClick={handleSubmitQr}>ثبت</button>
                    </div>
                </div>
            }
            <p className='title'>
                کاربر گرامی با اسکن qr-code مندرج بر روی بسته بندی محصول خریداری شده میتوانید اطلاعات محصول را مشاهده کرده و سپس اقدام به ثبت و فعال سازی گارانتی خود بکنید
            </p>
            <div className="qrCodeContainer">
                {
                    loader &&
                    <div className="loader">
                        <Blocks
                            visible={true}
                            height="120"
                            width="120"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                }
                {
                    !loader ?
                        <BarcodeScannerComponent
                            torch={torchState}
                            width={300}
                            height={300}
                            stopStream={stopScan}
                            facingMode='environment'
                            onUpdate={(err, result) => {
                                if (result) {
                                    handleGetQrData(result.text)
                                    setQr(result.text)
                                    setStopScan(true)
                                }
                                else {
                                }
                            }}
                        />
                        : null
                }
                {
                    torchState ?
                        <MdOutlineFlashlightOn onClick={() => setTorchState(false)} size={35} />
                        :
                        <MdOutlineFlashlightOff onClick={() => setTorchState(true)} size={35} />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GarantySubmit);
