import React, { useState, useEffect } from "react";
import "./BasketForm.scss";

import { Link, useNavigate } from "react-router-dom";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import { fetchApi } from "../../defz";

import { ToastContainer, toast } from "react-toastify";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleNotch);

function BaksetFromV({ token }) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [payMethod, setPayMethod] = useState("onlion");
  const [address, setAddresses] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchApi(`api/user/fetchMyAccount`, token, "").then((res) => {
      setAddresses(res.adress);
    });
  }, [token]);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  const validationSchema = yup
    .object({
      address: yup.string().required().required("آدرس انتخاب شود"),
      description: yup.string().default(),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    $(".buttonText").css({ display: "none" });
    $(".buttonSpin").css({ display: "block" });

    e.preventDefault();
    let basket = JSON.parse(localStorage.getItem("basket"));
    let offcode = localStorage.getItem("offCode");
    offcode = offcode === null ? "" : offcode;

    fetch("https://rabet.iran.liara.run/api/user/shop/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Auth-Token": token,
        "X-Token": "web",
      },
      body: JSON.stringify({
        product_list: basket,
        discode: offcode,
        pay_type: payMethod,
        address: data.address,
        dis: data.description,
      }),
    })
      .then((respone) => respone.json())
      .then((res) => {
        if (res.description === "pay") {
          window.open(res.link, "windowName", "target= _blank");
        } else if (res.description === "submited successfully!") {
          toast.success("سفارش شما ثبت شد", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.removeItem("basket");
          localStorage.removeItem("offCode");
          $("#basketSubmitButton").css({ display: "none" });
          setTimeout(() => {
            navigate("/profile");
          }, 5000);
        }
      });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="cover" onClick={rejectCoverHandler}></div>
      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-basketForm">
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <div className="title">جزئیات پرداخت</div>
          <div className="row">
            <div className="col">
              <label htmlFor="">آدرس</label>
              <select
                name="state"
                id="address"
                className={`${errors.address ? "is-invalid" : ""}`}
                {...register("address")}
              >
                <option value="">آدرس</option>
                {address.map((address, index) => {
                  return (
                    <option key={index} value={address.address}>
                      {address.address}
                    </option>
                  );
                })}
              </select>
              <small className="allert">{errors.address?.message}</small>
            </div>
            <div className="col">
              <label htmlFor="">ثبت آدرس</label>
              <Link to="/profile">کلیک کنید</Link>
            </div>
          </div>
          <div className="row">
            <div className="col-100">
              <label htmlFor="">توضیحات</label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="10"
                className={`${errors.description ? "is-invalid" : ""}`}
                {...register("description")}
              ></textarea>
            </div>
          </div>
          <div className="title">روش پرداخت</div>
          <div className="row-pay">
            <div className="title">لطفا روش پرداخت خود را مشخص کنید</div>
            <div className="way-pay">
              <input
                type="radio"
                onClick={() => {
                  setPayMethod("onlion");
                }}
                name="pay"
                id=""
                defaultChecked
              />{" "}
              پرداخت آنلاین
            </div>
            <div className="way-pay">
              <input
                type="radio"
                name="pay"
                id=""
                onClick={() => {
                  setPayMethod("inDoor");
                }}
              />{" "}
              پرداخت هنگام دریافت
            </div>
          </div>
          <div className="row">
            <div className="col-rule">
              <input type="radio" name="" id="" defaultChecked />
              من قوانین و مقررات شرایط و مقررات را خواندم و آن را می پذیرم
            </div>
            <div className="col">
              <button id="basketSubmitButton">
                <span className="buttonText">ثبت سفارش</span>
                <span className="buttonSpin">
                  <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BaksetFromV);
