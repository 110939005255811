import React, { useState, useEffect } from "react";
import "./Pay.scss";

import $ from "jquery";

import {useNavigate} from 'react-router-dom';

import { getProductCountBasket } from "../../defz";

import succesPayImg from "../../assets/images/payment_successful.gif";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SignIn from "../../components/SignIn/SignIn";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function SuccessPay() {
  const localId = document.URL.split("?")[1];
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("basket");
    localStorage.removeItem("offCode");
  }, []);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  const findDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const handleSubmit = () => {
    const deviceType = findDeviceType();
    const isAndroid = /(android)/i.test(navigator.userAgent)
    // alert(isAndroid)
    
    if (isAndroid && deviceType === 'mobile' || deviceType === 'tablet') {
      window.location.href = 'https://rabetettehadco.ir';
    } else {
      navigate('/', {replace: true});
    }
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>

      {sigIn ? <SignIn codeHandler={codeHandler} /> : null}
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}

      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <main className="index-successPay">
        <img src={succesPayImg} alt="succesPayImg" />
        <span className="title-content">بله!</span>
        <span className="subbtitle-content">پرداخت موفق بود</span>
        <span className="details-content">کد پیگیری : {localId}</span>
        <span className="site-contect">www.RabetEttehadco.ir</span>
        <a href="https://www.rabetettehadco.ir">بازگشت به صفحه اصلی</a>
      </main>
      <Footer />
    </>
  );
}

export default SuccessPay;
