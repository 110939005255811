import React, { useState } from "react";

import signIn from "../../assets/images/signIn.png";

import $ from "jquery";

import { fetchApi } from "../../defz";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleNotch);

function SignIn({ codeHandler }) {
  const [phoneNumber, setPhoneNumber] = useState();

  const submitHandler = () => {
    if (
      phoneNumber.indexOf(0) === 0 &&
      phoneNumber.indexOf(9) === 1 &&
      phoneNumber.length === 11
    ) {
      $("#phoneNumber").css({ border: "1px solid #9e9e9e" });
      $("#allert").css({ display: "none" });
      $(".buttonText").css({ display: "none" });
      $(".buttonSpin").css({ display: "flex" });
      localStorage.setItem("phoneNumber", phoneNumber);
      // localStorage.setItem("register", )

      fetchApi(`/api/user/send_v_code/phone_number=${phoneNumber}`, "").then(
        (res) => {
          if (res.description === "Verification SMS has been sent!") {
            localStorage.setItem("register", res.registered);
            codeHandler("send");
          }
        }
      );
    } else {
      $("#phoneNumber").css({ border: "1px solid red" });
      $("#allert").css({ display: "flex" });
      $(".buttonText").css({ display: "flex" });
      $(".buttonSpin").css({ display: "none" });
    }
  };

  return (
    <>
      <div className="signInCard">
        <section className="signInPic">
          <img src={signIn} alt="signIn" />
        </section>
        <section className="content">
          <div className="title">ورود و ثبت نام</div>
          <input
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            type="text"
            id="phoneNumber"
            placeholder="شماره موبایل خود را وارد کنید"
          />
          <small id="allert">شماره موبایل اشتباه است</small>
          <button onClick={submitHandler}>
            <span className="buttonText">ورود</span>
            <span className="buttonSpin">
              <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
            </span>
          </button>
        </section>
      </div>
    </>
  );
}

export default SignIn;
